<template>
  <v-container fluid fill-height>
    <v-layout align-start>
      <v-flex>
        <!-- Ventas -->
        <v-data-table :loading="loading" :headers="cabeceraListarVenta" :items="ventas" :items-per-page="15"
          class="lcyan elevation-3" dense>
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title class="hidden-sm-and-down dcyan--text">VENTAS USUARIO</v-toolbar-title>
              <v-divider class="mx-2 hidden-sm-and-down" inset vertical></v-divider>
              <v-spacer />
              <v-btn icon small color="print" @click="crearPDF()" :disabled="desabilitar || !isValid" class="mr-1">
                <v-icon>print</v-icon>
              </v-btn>
              <v-spacer />
              <!-- Formulario busqueda de venta -->
              <v-autocomplete dense @keyup="selectUsuario()" :search-input.sync="buscar_usuario" v-model="idusuario"
                :items="usuarios" label="Usuario" :rules="[v => !!v || 'Nombres es requerido']" clearable hide-details
                class="mr-1" @change="listar"></v-autocomplete>
              <v-menu v-model="menuFecha" :close-on-content-click="!menuFecha" transition="scale-transition" offset-y
                min-width="290px" :close-on-click="dates.length == 2 ? true : false">
                <template v-slot:activator="{ on }">
                  <v-text-field dense v-model="dateRangeText" readonly v-on="on" hide-details append-icon="update"
                    @click:append="listar()" class="mr-1"></v-text-field>
                </template>
                <v-date-picker v-model="dates" @input="dates.length == 2 ? menuFecha = false : menuFecha = true"
                  @change="listar" range></v-date-picker>
              </v-menu>
              <div id="container" style="position: absolute; visibility: hidden;" class="qrcode" ref="qrCodeUrl"></div>
              <v-spacer />
              <!-- Fin Formulario busqueda de venta -->

              <!-- Modal enviar email Venta -->
              <v-dialog v-model="adModalEmailCliente" max-width="500" persistent>
                <v-card>
                  <v-card-title class="text-h6">
                    {{ adComprobante }}
                    <v-spacer />
                    <v-btn icon @click="emailCerrar">
                      <v-icon>close</v-icon>
                    </v-btn>
                  </v-card-title>
                  <v-card-subtitle class="justify-center">{{ cliente }}</v-card-subtitle>
                  <v-card-text>
                    <v-form v-model="isValid">
                      <v-text-field dense class="text-xs-center" v-model.trim="email" label="Email"
                        :rules="[v => /.+@.+\..+/.test(v) || 'Ingrese un email valido', Rules.sinespacio]"
                        maxlength="60" v-lowercase hide-details></v-text-field>
                    </v-form>
                  </v-card-text>
                  <v-card-actions class="py-0 pb-4 justify-center">
                    <v-btn color="success" class="white--text" :loading="desabilitar"
                      :disabled="desabilitar || !isValid" @click="enviarEmail()" small>
                      <v-icon left>send</v-icon>Enviar
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <!-- Fin Modal enviar email Venta -->
            </v-toolbar>
          </template>
          <!-- Opciones ventas -->

          <template v-slot:item.numero="{ item }">
            <template v-if="item.idtipo_comprobante != '00'">
              <strong>{{ item.serie_comprobante + "-" + item.num_comprobante }}</strong>
            </template>

            <template v-else>
              <span>{{ item.serie_comprobante + "-" + item.num_comprobante }}</span>
            </template>
          </template>

          <template v-slot:item.cliente="{ item }">
            <span>{{ (item.cliente).substr(0, 20) }}</span>
          </template>

          <template v-slot:item.fecha="{ item }">
            <span>{{ item.fecha | formatearFecha }}</span>
          </template>

          <template v-slot:item.moneda="{ item }">
            <span>{{ item.moneda == "S" ? "S/" : "$" }}</span>
          </template>

          <template v-slot:item.importe_total="{ item }">
            <div class="text-right">
              <strong>{{ item.importe_total | formatearNumero }}</strong>
            </div>
          </template>

          <template v-slot:item.opciones="{ item }">
            <v-btn small icon>
              <v-icon color="print" @click="mostrarComprobante(item)">print</v-icon>
            </v-btn>
          </template>
          <!-- Fin Opciones de impresion, anular y cpe-->

          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <strong>Registrado por:</strong>
              {{ item.usuario }}
              <br />
              <strong v-if="item.msj_cdr.length > 0">SUNAT:</strong>
              {{ item.msj_cdr }}
            </td>
          </template>

          <template slot="body.append">
            <tr>
              <td :colspan="5" class="text-right font-weight-bold">TOTAL S/:</td>
              <td class="text-right font-weight-bold">{{ common.roundOut(totalImporte, 2) | formatearNumero }}
              </td>
            </tr>
          </template>
          <!-- Fin Opciones ventas -->
        </v-data-table>
        <!--Fin Ventas -->

        <!--Modal impresión -->
        <v-dialog v-model="comprobanteModal" :max-width="datos.formatimpresion == '1' ? '400px' : '600px'" scrollable>
          <v-card>
            <v-toolbar dark dense class="dcyan" flat>
              <v-toolbar-title>{{ comprobante }}</v-toolbar-title>
              <v-spacer />
              <v-btn icon @click="printExternal()">
                <v-icon>print</v-icon>
              </v-btn>
              <v-btn icon @click="emailMostrar()" v-if="idtipo_comprobante != '00'" :disabled="disableBMail">
                <v-icon>attach_email</v-icon>
              </v-btn>
              <v-btn icon @click="savePdf()">
                <v-icon>download</v-icon>
              </v-btn>
              <v-btn icon @click="ocultarComprobanteImp()">
                <v-icon>close</v-icon>
              </v-btn>
            </v-toolbar>
            <v-card-text style="height: 400px;" class="px-0">
              <pdf :src="stringPdf"></pdf>
            </v-card-text>
          </v-card>
        </v-dialog>
        <!-- Fin Modal impresión -->
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import axios from "axios";
import jsPDF from "jspdf";
import numeral from "numeral";
import moment from "moment";
import pdf from "vue-pdf";
import { mapGetters, mapState, mapActions } from "vuex";
import QRCode from "qrcodejs2";

export default {
  components: {
    pdf,
  },
  data() {
    return {
      ventas: [],
      cabeceraListarVenta: [
        { text: "ESTABLECIMIENTO", value: "nom_establecimiento", sortable: false },
        { text: "NÚMERO", value: "numero", sortable: false },
        { text: "CLIENTE", value: "cliente", sortable: false },
        { text: "FECHA", value: "fecha", sortable: false },
        { text: "", value: "moneda", align: "right" },
        { text: "IMPORTE", value: "importe_total", sortable: false },
        { text: "PAGO", value: "descrip_pago", sortable: false },
        { text: "ESTADO", value: "estado", sortable: false },
        //{ text: "", value: "descargas", sortable: false },
        { text: "OPCIÓN", value: "opciones", sortable: false },
      ],

      buscar_usuario: "",
      idusuario: "",
      usuarios: [],

      detalles: [], // Detalles pedido
      detalle_pedidos: [], // Detalles formateado
      detalleCuentasxC: [],

      idmovimiento: "",
      idpedido: 0,

      sucursal: "",
      cliente: "",
      fecha: "",
      num_documento: "",
      importe_letras: "",
      direccion: "",
      telefono: "",
      email: "",
      comprobante: "",
      serie_comprobante: "",
      num_comprobante: "",
      moneda: "",
      igv: 0,

      descto_global: 0,
      descto_item_total: 0,
      subtotal_gravado: 0,
      gratuito: 0,
      igv_gravado: 0,
      importe_total: 0,

      ref_comprobante: "",
      afecta_stock: "",

      idtipo_documento: "",
      idpersona: "",

      total_pago: 0,
      // Ventas

      idtipo_pago: "",
      idtipo_comprobante: "", // 00=Recibo Interno, 01=Factura, 03=Boleta de Venta

      fecha_vencimiento_formateada: "",
      fecha_vencimiento: moment().format("YYYY-MM-DD"),
      fecha_actual: moment().format("YYYY-MM-DD"),

      menuCalendario: false,
      // Fin Ventas

      comprobanteModal: 0,
      desabilitar: false,
      isValid: true, // Validamos
      disableBMail: true,

      adModal: 0,
      adComprobante: 0,
      motivo: "",

      adModalEmailCliente: 0,

      stringPdf: "",
      blobPdf: "",

      doc_ref: "", // Numero de comprobante FE referencia
      cod_doc_ref: "", // Codigo de tipo documento FE referencia

      dates: [
        moment().startOf("month").format("YYYY-MM-DD"),
        moment().endOf("month").format("YYYY-MM-DD"),
      ],
      menuFecha: false,

      /* Validaciones */
      Rules: {
        sinespacio: (v) =>
          (v || "").indexOf(" ") < 0 || "No se permite espacios",
        unespacio: (v) =>
          (v || "").indexOf("  ") < 0 || "No se permite mas de 1 espacio",
      },
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "logo"]),
    ...mapGetters(["esAdministrador", "esCaja"]),

    dateRangeText() {
      let fechaIni;
      let fechaFin;
      for (var i = 0; i < this.dates.length; i++) {
        fechaIni = this.common.formatDate(this.dates[0]);
        fechaFin = this.common.formatDate(this.dates[1]);
      }
      let arrayFechas = [fechaIni, fechaFin];
      return arrayFechas.join(" ~ ");
    },

    totalImporte() {
      const total = this.ventas.reduce((acc, venta) => {
        return acc + (venta.importe_total || 0);
      }, 0);
      return total;
    }
  },

  watch: {
    comprobanteModal(val) {
      val || this.ocultarComprobanteImp();
    }
  },

  created() {
    this.selectUsuario();
    this.igv = parseFloat(this.datos.igv);
    this.tc = parseFloat(this.datos.tc);
    this.fechaseleccionada(this.fecha_vencimiento);
    this.listar();
  },

  methods: {
    ...mapActions(["snackBar"]),

    fechaseleccionada(dia) {
      let arrayFecha = dia.split(["-"]);
      let ddmmyy = arrayFecha[2] + "/" + arrayFecha[1] + "/" + arrayFecha[0];
      this.fecha_vencimiento_formateada = ddmmyy;
    },

    //#region VENTAS
    listar(printid) {
      let me = this;
      me.idusuario == "" ? me.idusuario = parseInt(me.usuario.idusuario) : me.idusuario
      me.loading = true;
      //idsucursal: parseInt(me.usuario.idsucursal),

      let adVenta = me.$route.params.adventa;
      let dePedido = me.$route.params.depedido; // true
      let deidpersona = me.$route.params.deidpersona;
      let deidpedido = me.$route.params.deidpedido;

      if (adVenta === true) {

        me.$route.params.adventa = false;
      }

      if (dePedido == true) {
        me.idpersona = deidpersona;
        me.idpedido = deidpedido;
        me.listarDetallePedidos(deidpedido);

        me.$route.params.depedido = false;
      }

      axios.get("api/Movimientos/ReporteVentaUsuario", {
        params: {

          idusuario: me.idusuario,
          fechaInicio: me.dates[0],
          fechaFin:
            me.dates[1] == undefined
              ? me.dates[0] + " 23:59:59"
              : me.dates[1] + " 23:59:59",
        },
      })
        .then(function (response) {
          me.ventas = response.data;
          me.loading = false;
          if (printid) {
            me.cargaComprobante(printid);
          }
        })
        .catch(function (error) {
          //console.log(error);
        });

    },
    //#endregion VENTAS

    //#region USUARIOS
    selectUsuario() {
      let me = this;

      var usuariosArray = [];

      axios.get("api/Usuarios/SelectUsuarioFiltro", {
        params: {
          buscar: me.buscar_usuario == "" ? "-" : me.buscar_usuario,
        },
      })
        .then(function (response) {
          usuariosArray = response.data;
          usuariosArray.map(function (x) {
            me.usuarios.push({
              //text: x.num_documento + " " + x.nombre,
              text: x.nombre,
              value: x.idusuario,
            });
          });
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion USUARIOS

    //#region IMPRESION
    // Para descargar pdf de blob
    savePdf() {
      var link = document.createElement("a");
      link.href = window.URL.createObjectURL(this.blobPdf);
      var fileName = this.serie_comprobante + "-" + this.num_comprobante;
      link.download = fileName;
      link.click();
    },

    // Llamamos de main.js printJS
    printExternal() {
      printJS({
        printable: this.stringPdf.replace("data:application/pdf;filename=generated.pdf;base64,", ""),
        type: "pdf",
        base64: true,
      });
    },

    cargaComprobante(id) {
      this.limpiar();

      const selectId = this.ventas.find(
        (elemento) => elemento.idmovimiento === id
      );

      this.idmovimiento = selectId.idmovimiento;
      this.idtipo_pago = selectId.idtipo_pago;
      if (this.idtipo_pago == 4) { this.listarDetalleCuentasxC(this.idmovimiento); }
      this.sucursal = selectId.sucursal;
      this.nomusuario = selectId.usuario;
      this.idtipo_comprobante = selectId.idtipo_comprobante;
      this.comprobante = selectId.comprobante;
      this.serie_comprobante = selectId.serie_comprobante;
      this.num_comprobante = selectId.num_comprobante;
      this.subtotal_gravado = selectId.subtotal_gravado;
      this.descto_item_total = selectId.descto_item_total;
      this.gratuito = selectId.gratuito;
      this.igv_gravado = selectId.igv_gravado;
      this.importe_total = selectId.importe_total;
      this.importe_letras = selectId.importe_letras;
      //this.placa = selectId.placa;
      this.hash_cpe = selectId.hash_cpe;
      this.ref_comprobante = selectId.ref_comprobante;
      this.motivo = selectId.motivo;
      this.idpedido = selectId.idpedido;
      this.listarDetallePedidos(selectId.idpedido);
      // Cliente
      this.idtipo_documento = selectId.idtipo_documento;
      this.cliente = selectId.cliente;
      this.num_documento = selectId.num_documento;
      this.direccion = selectId.direccion;
      this.nom_ubigeo = selectId.nom_ubigeo;
      this.telefono = selectId.telefono;
      this.email = selectId.email;
      this.fecha = selectId.fecha;
      this.descrip_pago = selectId.descrip_pago;
      this.igv = selectId.igv;
      this.moneda = selectId.moneda;
      this.comprobanteModal = 1;
    },

    mostrarComprobante(item) {
      this.cargaComprobante(item.idmovimiento);
    },

    // Lista detalle de cuenta x cobrar
    async listarDetalleCuentasxC(id) {
      let me = this;
      me.detalleCuentasxC = [];
      const response = await axios.get("api/Detalle_Cuentas/ListarDetallesCxC/" + id)
        .then(function (response) {
          me.detalleCuentasxC = response.data;
        })
        .catch(function (error) {
          //console.log(error);
        });
    },

    ocultarComprobanteImp() {
      this.comprobanteModal = 0;
      this.disableBMail = true;
      this.detalleCuentasxC = [];
      // Limpiamos div carga de qr
      document.getElementById("container").innerHTML = "";
      this.limpiar();
    },
    //#endregion IMPRESION

    //#region det. VENTA, NOTA DE CREDITO, IMPRESION
    listarDetallePedidos(id) {
      let me = this;
      var detallesArray = [];

      axios.get("api/Pedidos/ListarDetallesPedidos/" + id)
        .then(function (response) {
          detallesArray = response.data; // recorremos el array de detallesArray de ventas para formatear el precio_unit_venta
          detallesArray.map(function (x) {
            me.detalles.push({
              idproducto: x.idproducto,
              idunidad_medida: x.idunidad_medida,
              codigo_producto: x.codigo_producto,
              descripcion_adicional: x.descripcion_adicional,
              stock_actual: x.stock_actual,
              iddetalle_compra: x.iddetalle_compra,
              cantidad: x.cantidad,
              cant_tope: x.cantidad,
              precio_unit_venta: x.precio_unit_venta.toFixed(2),
              descto_item: x.descto_item,
              precio_venta: x.precio_venta,
              idprod_sunat: x.idprod_sunat,
              idtipo_afectacion: x.idtipo_afectacion,
              precio_unit_compra: me.common.roundOut((x.idtipo_servicio == 3 ? x.precio_unit_compra : x.precio_unit_compra * me.tc), 2) // > compra
            });
          });
          if (me.datos.formatimpresion == "1") {
            me.crearPDFTK();
          } else if (me.datos.formatimpresion == "2") {
            me.crearPDFA4();
          } else {
            console.log("No existe un formato definido de impresión.");
          }
          // console.log(me.detalles);
        })
        .catch(function (error) {
          //console.log(error);
        });
    },
    //#endregion det. VENTA, NOTA DE CREDITO, IMPRESION

    //#region EMAIL
    enviarEmail() {
      let me = this;
      me.desabilitar = true; // Desabilitamos boton enviar

      axios.put("api/Movimientos/EnviarEmail", {
        idmovimiento: me.idmovimiento,
        cliente: me.cliente,
        email: me.email,
      })
        .then(function (response) {
          me.snackBar({ cl: response.data.rptcolor, msg: response.data.respuesta });
          me.emailCerrar();
        })
        .catch(function (error) {
          me.snackBar({ cl: "error", msg: error.response.data });
        });
    },

    emailMostrar() {
      this.adModalEmailCliente = 1;
      this.adComprobante = this.serie_comprobante + "-" + this.num_comprobante;
    },

    emailCerrar() {
      this.adModalEmailCliente = 0;
      this.desabilitar = false;
    },
    //#endregion EMAIL


    limpiar() {
      this.igv = parseFloat(this.datos.igv); // requerido
      this.idmovimiento = "";
      this.comprobante = "";
      this.serie_comprobante = "";
      this.num_comprobante = "";
      this.importe_letras = "";
      this.adComprobante = "";
      this.nomusuario = "";
      //this.placa = "";
      this.hash_cpe = "";
      this.doc_ref = "";
      this.cod_doc_ref = "";
      this.subtotal_gravado = 0;
      this.descto_item_total = 0;
      this.gratuito = 0;
      this.igv_gravado = 0;
      this.importe_total = 0;
      this.detalles = [];
      this.detalle_pedidos = [];
      this.idpedido = 0;
      // Cliente
      this.cliente = "";
      this.num_documento = "";
      this.direccion = "";
      this.telefono = "";
      this.email = "";
      this.fecha = "";
      this.moneda = "";
      this.ref_comprobante = "";
      this.motivo = "";
      this.afecta_stock = "";
      this.desabilitar = false;
      // Venta
      this.idtipo_comprobante = "";
      this.idpersona = "";
      this.idtipo_documento = "";
      this.idtipo_pago = "";
      this.total_pago = 0;
      this.fecha_vencimiento = moment().format("YYYY-MM-DD"); // Cronograma de pagos
      this.fechaseleccionada(this.fecha_vencimiento); // Cargamos fecha actual a fecha_vencimiento y fecha_vencimiento_formateada
      this.stringPdf = "";
      this.blobPdf = "";
    },

    crearPDFTK() {
      var doc = new jsPDF("p", "mm", [78, 270]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text: this.datos.ruc + "|" + this.idtipo_comprobante + "|" + this.serie_comprobante + "|" + this.num_comprobante + "|" + this.igv_gravado + "|" + this.importe_total + "|" + moment(this.fecha).format("DD/MM/YYYY") + "|" + this.idtipo_documento + "|" + this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        // Fin QR
      }

      var columns = [
        { header: "DESCRIPCIÓN", dataKey: "descripcion_adicional" },
        { header: "CANT.", dataKey: "cantidad" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "DTO", dataKey: "descto_item" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          descripcion_adicional: this.detalles[i].descripcion_adicional,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format("###,##0.00"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.00"),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          if (this.idtipo_comprobante == "00") {
            foot.push(
              [
                {
                  content: "DSCTO ITEM S/ ",
                  colSpan: 4,
                },
                numeral(this.descto_item_total).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 4,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]);
          } else {
            foot.push(
              [
                {
                  content: "GRAVADA S/ ",
                  colSpan: 3,
                },
                numeral(this.subtotal_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "GRATUITA S/ ",
                  colSpan: 3,
                },
                numeral(this.gratuito).format("###,##0.00"),
              ],
              [
                {
                  content: "IGV S/ ",
                  colSpan: 3,
                },
                numeral(this.igv_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 3,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]
            );
          }
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", centro / 2, 4, 36, 18);

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 5, right: 5, top: 24 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
          halign: "center",
        },

        body: [
          { columna0: this.datos.ruc, },
          { columna0: this.datos.dirsucursal, },
          { columna0: this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal, },
          { columna0: this.datos.mailsucursal },
          { columna0: this.datos.telsucursal },
          { columna0: nomcomprob },
          { columna0: this.serie_comprobante + "-" + this.num_comprobante },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
        // En negrita las filas
        didParseCell: function (data) {
          if (data.row.index === 0) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.index === 5) {
            data.cell.styles.fontStyle = "bold";
          }
          if (data.row.index === 6) {
            data.cell.styles.fontStyle = "bold";
          }
        },
      });

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 2,
        theme: "plain",
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        margin: { left: 5, right: 5 },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 7,
          textColor: 0,
        },
        body: [
          //{ columna0: "VENDEDOR: " + this.nomusuario },
          { columna0: "FECHA DE EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"), },
          //{ columna0: "FECHA DE VENCIMIENTO: " + (this.idtipo_pago == 4 ? moment(this.detalleCuentasxC[0].fecha_vencimiento).format("DD/MM/YYYY") : moment(this.fecha).format("DD/MM/YYYY")), },
          { columna0: "CLIENTE: " + this.cliente },
          { columna0: "RUC/DNI: " + this.num_documento },
          { columna0: "DIRECCIÓN: " + this.direccion + " - " + this.nom_ubigeo, },
          //{ columna0: "PLACA: " + this.placa },
        ],
      });

      // DETALLE DE VENTA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 1,
        theme: "plain",
        columns,
        body,
        foot,
        margin: { left: 5, right: 5 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.3,
          fontSize: 6,
          halign: "center",
        },
        bodyStyles: { cellPadding: 0.5, textColor: 0, fontSize: 6 },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          descripcion_adicional: { cellWidth: "auto" },
          cantidad: { cellWidth: 10, halign: "center" },
          precio_unit_venta: { cellWidth: 12, halign: "right" },
          descto_item: { cellWidth: 10, halign: "right" },
          subtotal: { cellWidth: 14, halign: "right" },
        },
      });
      doc.setFont("helvetica", "normal");
      doc.setFontSize(7);

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 5, finalY + 3);
      // Factura y/o Boleta
      if (
        this.idtipo_comprobante == "00" ||
        this.idtipo_comprobante == "01" ||
        this.idtipo_comprobante == "03"
      ) {
        doc.text("FORMA DE PAGO: " + this.descrip_pago, 5, finalY + 7);
        // Cuadro para firma 
        doc.setFontSize(5);
        doc.text("// TICKET ADMINISTRATIVO //", 5, finalY + 11);
        doc.autoTable({
          startY: doc.lastAutoTable.finalY + 12,
          theme: "grid",
          margin: { left: 5, right: 5 },

          headStyles: {
            cellPadding: 5,
            fillColor: [255, 255, 255],
            lineColor: 0,
            lineWidth: 0.2,
          },
          columnStyles: {
            columna0: { cellWidth: 34 },
            columna1: { cellWidth: 34 },
          },
          columns: [
            { header: "-", dataKey: "columna0" },
            { header: "-", dataKey: "columna1" },
          ],
        });

        doc.text("ENTREGADO", 15, finalY + 28);
        doc.text("CANCELADO", 50, finalY + 28);

      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 5, finalY + 7);
        doc.text("DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16), 5, finalY + 11);
      }

      // Referencia de comprobante convertido
      if (this.idtipo_comprobante == "00" && this.ref_comprobante != "") {
        doc.text("REFERENCIA: " + this.ref_comprobante.substring(3, 16), 5, finalY + 15);
      }

      if (imageQR) {
        doc.text("Representacion Impresa de la " + this.comprobante + " Electronica", centro, finalY + 19, "center");
        doc.text("consulte su comprobante en SUNAT", centro, finalY + 23, "center");
        doc.addImage(imageQR, "JPEG", 26.5, finalY + 26, 25, 25);
        //doc.text("GRACIAS POR SU COMPRA", centro, finalY + 47, "center");
        doc.text("RESUMEN", centro, finalY + 50, "center");
        doc.setFontSize(6);
        doc.text(this.hash_cpe, centro, finalY + 54, "center");
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      /* Crear PDF venta */
    },

    crearPDFA4() {
      var doc = new jsPDF("p", "mm", [210, 297]);
      var centro = doc.internal.pageSize.width / 2;
      var imgData = "data:image/png;base64," + this.logo;

      var nomcomprob = "";

      if (this.idtipo_comprobante == "00") {
        nomcomprob = this.comprobante.toUpperCase();
      } else {
        // FE
        nomcomprob = this.comprobante.toUpperCase() + " ELECTRONICA";
        // QR
        var canvasDiv = document.createElement("div"); // Nuevo elemento dom, si solo hay una página, puede usar el elemento de página directamente
        canvasDiv.id = "canvas";
        this.$refs.qrCodeUrl.append(canvasDiv);
        var qrcode = new QRCode("canvas", {
          // qrcode genera un código QR
          text: this.datos.ruc + "|" + this.idtipo_comprobante + "|" + this.serie_comprobante + "|" + this.num_comprobante + "|" + this.igv_gravado + "|" + this.importe_total + "|" + moment(this.fecha).format("DD/MM/YYYY") + "|" + this.idtipo_documento + "|" + this.num_documento,
          width: 80,
          height: 80,
          colorDark: "#000000",
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.H,
        });
        let canvas = document.getElementById("canvas").querySelector("canvas"); // Obtener el elemento canvas
        var imageQR = canvas.toDataURL();
        canvas = null;
        // Fin QR
      }

      var columns = [
        { header: "CANT.", dataKey: "cantidad" },
        { header: "U.M.", dataKey: "idunidad_medida" },
        { header: "DESCRIPCIÓN", dataKey: "descripcion_adicional" },
        { header: "P/U", dataKey: "precio_unit_venta" },
        { header: "DSCTO", dataKey: "descto_item" },
        { header: "TOTAL", dataKey: "subtotal" },
      ];
      var body = [];
      var foot = [];

      for (var i = 0; i < this.detalles.length; i++) {
        body.push({
          cantidad: this.detalles[i].cantidad,
          idunidad_medida: this.detalles[i].idunidad_medida,
          descripcion_adicional: this.detalles[i].descripcion_adicional,
          precio_unit_venta: numeral(this.detalles[i].precio_unit_venta).format("###,##0.00"),
          descto_item: numeral(this.detalles[i].descto_item).format("###,##0.00"),
          subtotal: numeral(this.detalles[i].precio_venta).format("###,##0.00"),
        });

        if (i === this.detalles.length - 1) {
          if (this.idtipo_comprobante == "00") {
            foot.push(
              [
                {
                  content: "DSCTO ITEM S/ ",
                  colSpan: 5,
                },
                numeral(this.descto_item_total).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 5,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]);
          } else {
            foot.push(
              [
                {
                  content: "GRAVADA S/ ",
                  colSpan: 4,
                },
                numeral(this.subtotal_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "GRATUITA S/ ",
                  colSpan: 4,
                },
                numeral(this.gratuito).format("###,##0.00"),
              ],
              [
                {
                  content: "IGV S/ ",
                  colSpan: 4,
                },
                numeral(this.igv_gravado).format("###,##0.00"),
              ],
              [
                {
                  content: "IMPORTE TOTAL S/ ",
                  colSpan: 4,
                  styles: { fontStyle: "bold" },
                },
                {
                  content: numeral(this.importe_total).format("###,##0.00"),
                  styles: { fontStyle: "bold" },
                },
              ]
            );
          }
        }
      }
      // Logo
      doc.addImage(imgData, "JPEG", 15, 19, 36, 18);

      // Datos empresa
      doc.autoTable({
        theme: "plain",
        margin: { left: 50, right: 70, top: 17 },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "bold",
          fontSize: 10,
          halign: "center",
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 9,
          textColor: 0,
          halign: "center",
        },
        body: [
          { columna0: this.datos.dirsucursal + " " + this.datos.dptosucursal + " - " + this.datos.provsucursal + " - " + this.datos.dttosucursal, },
          { columna0: "Email: " + this.datos.mailsucursal },
          { columna0: "Atención Cliente: " + this.datos.telsucursal },
        ],
        columns: [{ header: this.datos.empresa, dataKey: "columna0" }],
      });

      // COMPROBANTE DE PAGO
      doc.setFont("helvetica", "bold");
      doc.setFontSize(8);
      doc.setDrawColor(0);
      doc.roundedRect(141, 17, 54, 18, 1, 1);
      //doc.rect(141, 17, 54, 18);
      doc.text("RUC " + this.datos.ruc, 168, 22, "center");
      doc.text(nomcomprob, 168, 27, "center");
      doc.text(this.serie_comprobante + "-" + this.num_comprobante, 168, 32, "center");

      // Mostramos QR para el CPE
      if (imageQR) {
        doc.addImage(imageQR, "JPEG", 170, doc.lastAutoTable.finalY + 2, 25, 25);
      }

      // DESTINATARIO
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 5,
        theme: "plain",
        //tableLineColor: 0,
        //tableLineWidth: 0.2,
        margin: { left: 15, right: 45 },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
        },
        body: [
          //{ columna0: "VENDEDOR: " + this.nomusuario },
          { columna0: "FECHA DE EMISIÓN: " + moment(this.fecha).format("DD/MM/YYYY"), },
          //{ columna0: "FECHA DE VENCIMIENTO: " + (this.idtipo_pago == 4 ? moment(this.detalleCuentasxC[0].fecha_vencimiento).format("DD/MM/YYYY") : moment(this.fecha).format("DD/MM/YYYY")), },
          { columna0: "CLIENTE: " + this.cliente },
          { columna0: "RUC/DNI: " + this.num_documento },
          { columna0: "DIRECCIÓN: " + this.direccion + " - " + this.nom_ubigeo, },
          //{ columna0: "PLACA: " + this.placa },
        ],
      });

      // DETALLE DE VENTA
      doc.autoTable({
        startY: doc.lastAutoTable.finalY + 4,
        theme: "grid",
        columns,
        body,
        foot,
        margin: { left: 15, right: 15 },
        //styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal" | "bold",
          fontSize: 8,
          halign: "center",
          fillColor: [227, 228, 229],
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        bodyStyles: {
          cellPadding: 0.5,
          font: "helvetica",
          fontStyle: "normal",
          fontSize: 8,
          textColor: 0,
          lineColor: [227, 228, 229],
          lineWidth: 0.2,
        },
        footStyles: {
          cellPadding: 0.3,
          fontStyle: "normal",
          fillColor: 255,
          textColor: 0,
          fontSize: 8,
          halign: "right",
        },
        columnStyles: {
          cantidad: { cellWidth: 15, halign: "center" },
          idunidad_medida: { cellWidth: 15, halign: "center" },
          descripcion_adicional: { cellWidth: "auto" },
          precio_unit_venta: { cellWidth: 20, halign: "right" },
          descto_item: { cellWidth: 15, halign: "right" },
          subtotal: { cellWidth: 20, halign: "right" },
        },
      });

      let finalY = doc.lastAutoTable.finalY;
      doc.text("SON: " + this.importe_letras, 15, finalY + 4);
      // Factura y/o Boleta
      if (this.idtipo_comprobante == "00" || this.idtipo_comprobante == "01" || this.idtipo_comprobante == "03") {
        doc.text("FORMA DE PAGO: " + this.descrip_pago, 15, finalY + 8);
      }
      // Nota credito
      if (this.idtipo_comprobante == "07") {
        doc.text("MOTIVO: " + this.motivo, 15, finalY + 8);
        doc.text("DOC. AFECTADO: " + this.ref_comprobante.substring(3, 16), 15, finalY + 12);
      }

      // Referencia de comprobante convertido
      if (this.idtipo_comprobante == "00" && this.ref_comprobante != "") {
        doc.text("REFERENCIA: " + this.ref_comprobante.substring(3, 16), 15, finalY + 16);
      }

      // Si existe QR mostramos mensaje de CPE
      if (imageQR) {
        doc.setFont("helvetica", "normal");
        doc.text("Representacion Impresa de la " + this.comprobante + " Electronica. " + "Resumen " + this.hash_cpe, centro, finalY + 18, "center");
        doc.text("consulte su comprobante en SUNAT", centro, finalY + 22, "center");
      }

      this.stringPdf = doc.output("datauristring");
      this.blobPdf = doc.output("blob");
      /* Crear PDF venta */
    },

    crearPDF() {
      var columns = [
        { header: "Establecimiento", dataKey: "nom_establecimiento" },
        //{ header: "CdMv", dataKey: "idmovimiento" },
        { header: "Número", dataKey: "num_comprobante" },
        { header: "Fecha", dataKey: "fecha" },
        { header: "Cliente", dataKey: "cliente" },
        { header: "", dataKey: "moneda" },
        { header: "Importe", dataKey: "importe_total" },
      ];
      var body = [];
      var foot = [];

      var totalimporte = 0.0;

      for (var i = 0; i < this.ventas.length; i++) {
        totalimporte =
          totalimporte + parseFloat(this.ventas[i].importe_total);
        body.push({
          nom_establecimiento: this.ventas[i].nom_establecimiento,
          //idmovimiento: this.ventas[i].idmovimiento,
          num_comprobante: this.ventas[i].serie_comprobante + "-" + this.ventas[i].num_comprobante,
          fecha: moment(this.ventas[i].fecha).format("DD/MM/YYYY"),
          cliente: this.ventas[i].cliente,
          moneda: this.ventas[i].moneda == "S" ? "S/" : "$",
          importe_total: numeral(this.ventas[i].importe_total).format(
            "###,##0.00"
          ),
        });

        if (i === this.ventas.length - 1) {
          foot.push([
            {
              content: "Total S/",
              colSpan: 5,
            },
            numeral(totalimporte).format("###,##0.00"),
          ]);
        }
      }

      // Titulo y logo
      const addHeaders = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.addImage(imgData, "JPEG", 40, 17, 80, 40);
          doc.setFont("helvetica", "bold");
          doc.setFontSize(10);
          doc.text("Ventas Usuario", doc.internal.pageSize.width / 2, 45, {
            align: "center",
          });
          doc.setFontSize(8);
          doc.text("NOMBRE: " + this.buscar_usuario, 40, 72);
          doc.text(
            "DESDE: " + moment(this.dates[0]).format("DD/MM/YYYY"),
            40,
            86
          );
          doc.text(
            "HASTA: " + moment(this.dates[1]).format("DD/MM/YYYY"),
            240,
            86
          );
        }
      };
      // Pie de pagina
      const addFooters = (doc) => {
        const pageCount = doc.internal.getNumberOfPages();

        doc.setFont("helvetica", "italic");
        doc.setFontSize(7);
        for (var i = 1; i <= pageCount; i++) {
          doc.setPage(i);
          doc.text(
            "Pagina " + String(i) + " de " + String(pageCount),
            doc.internal.pageSize.width / 2,
            820,
            {
              align: "center",
            }
          );
        }
      };

      var imgData = "data:image/png;base64," + this.logo;
      var doc = new jsPDF("p", "pt");

      doc.autoTable({
        columns,
        body,
        foot,
        margin: { top: 94 },
        styles: { overflow: "ellipsize", cellWidth: "wrap" },
        headStyles: {
          fillColor: [46, 78, 121],
          fontSize: 7,
          halign: "center",
        },
        bodyStyles: { textColor: 0, fontSize: 7 },
        footStyles: {
          fillColor: [255, 255, 255],
          textColor: 0,
          fontSize: 7,
          halign: "right",
        },
        columnStyles: {
          nom_establecimiento: { cellWidth: 80 },
          //idmovimiento: { cellWidth: 30, halign: "right" },
          num_comprobante: { cellWidth: 60, halign: "center" },
          fecha: { cellWidth: 45, halign: "center" },
          cliente: { cellWidth: "auto" },
          moneda: { cellWidth: 20, halign: "center" },
          importe_total: { cellWidth: 65, halign: "right" },
        },
      });

      addHeaders(doc);
      addFooters(doc);
      doc.save("VentasUsuario.pdf");
    },
  },
};
</script>